"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initToTop = void 0;
const constants_1 = require("../shared/constants");
const toTopElementId = `${constants_1.prefix}-to-top`;
const visibleClass = `visible`;
function initToTop() {
    console.log('init to top');
    toTopShowHideOnScroll();
}
exports.initToTop = initToTop;
function toTopShowHideOnScroll() {
    if (constants_1.debug)
        console.log('toTopShowHideOnScroll');
    document.addEventListener("scroll", toTopButtonVisibility);
}
function toTopButtonVisibility() {
    var toTopButton = document.getElementById(toTopElementId);
    if (!toTopButton)
        return;
    // Ensure it has the event listener
    toTopButton.addEventListener("click", scrollTop);
    // Todo: unsure why it does body + documentElement...
    const show = document.body.scrollTop > 200 || document.documentElement.scrollTop > 200;
    if (constants_1.debugDetailed)
        console.log('to-top' + show ? "show" : "hide");
    if (show)
        toTopButton.classList.add(visibleClass);
    else
        toTopButton.classList.remove(visibleClass);
}
function scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}
