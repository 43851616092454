"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initBreadcrumb = void 0;
const constants_1 = require("../shared/constants");
function initBreadcrumb() {
    if (constants_1.debug)
        console.log("init breadcrumbs");
    setBreadcrumbsStyling();
    window.addEventListener("resize", setBreadcrumbsStyling);
    document.addEventListener('scroll', setBreadcrumbsStyling);
    var breadCrumbTrigger = document.querySelector(`.${constants_1.prefixBreadcrumbs}-trigger`);
    if (breadCrumbTrigger != null) {
        breadCrumbTrigger.addEventListener('click', () => {
            console.log(document.querySelector(`.${constants_1.prefixBreadcrumbs}`));
            document.querySelector(`.${constants_1.prefixBreadcrumbs}`).classList.toggle(`${constants_1.prefixBreadcrumbs}-open`);
        });
    }
}
exports.initBreadcrumb = initBreadcrumb;
function setBreadcrumbsStyling() {
    var header = document.getElementById(`${constants_1.prefix}-page-navigation`);
    if (header != null) {
        var headerHeight = header.offsetHeight;
        var breadcrumbs = document.querySelector(`.${constants_1.prefixBreadcrumbs}`);
        breadcrumbs.style.top = headerHeight + "px";
        var breadcrumbsOffsetTop = breadcrumbs.getBoundingClientRect().top;
        if (breadcrumbsOffsetTop <= headerHeight && scrollY != 0) {
            breadcrumbs.classList.add("bg-gray-900", "shadow");
        }
        else {
            breadcrumbs.classList.remove("bg-gray-900", "shadow");
        }
    }
}
