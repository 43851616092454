"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initOffCanvasEvents = void 0;
const constants_1 = require("../shared/constants");
// Takes care of closing the offcanvas when clicking on a navigation link
// The page is not reloaded, so the offcanvas does not close
function initOffCanvasEvents() {
    if (constants_1.debug)
        console.log('initOffCanvasEvents');
    //Offcanvas close on link click
    var navLinks = document.querySelectorAll(".mobile-navigation-link");
    var navOffcanvas = document.getElementById('offcanvasNavbar');
    var bsNavOffcanvas = new bootstrap.Offcanvas(navOffcanvas);
    navLinks.forEach(element => {
        element.addEventListener('click', () => {
            bsNavOffcanvas.hide();
        });
    });
}
exports.initOffCanvasEvents = initOffCanvasEvents;
