"use strict";
// TODO: THIS PROBABLY doesn't work reliably, as it would need to be called again when the page changes
// will probably need to be moved to the interop and called on every reload
// or use a timeout similar to mail-decrypt
Object.defineProperty(exports, "__esModule", { value: true });
exports.openPdfInNewWindow = void 0;
/* Open all PDF links in a new window */
function openPdfInNewWindow() {
    document.querySelectorAll('a').forEach((linkElem, index) => {
        if (linkElem.hasAttribute('href') && linkElem.getAttribute('href').endsWith('.pdf')) {
            linkElem.setAttribute('target', '_blank');
        }
    });
}
exports.openPdfInNewWindow = openPdfInNewWindow;
