"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const mail_encrypt_1 = require("./mail-encrypt");
const menu_1 = require("./menu");
const breadcrumbs_1 = require("./breadcrumbs");
const pdf_in_new_window_1 = require("./pdf-in-new-window");
const to_top_1 = require("./to-top");
const gtm_1 = require("./gtm");
// import * as AOS from "aos";
// import './breadcrumbs';
(0, to_top_1.initToTop)();
(0, pdf_in_new_window_1.openPdfInNewWindow)();
(0, mail_encrypt_1.initMailDecrypt)();
(0, breadcrumbs_1.initBreadcrumb)();
(0, menu_1.initOffCanvasEvents)();
(0, gtm_1.buildCre8magicGtm)();
window.cre8magic.gtm.activate('GTM-T8W5TBL');
// 2022-11-15 2dm - disable AOS for now, causes too much trouble
// AOS.init({
//   // Global settings:
//   // Disable inspired by https://github.com/michalsnik/aos/issues/161
//   // Should help that google still sees the content
//   // But doesn't seem to work...
//     disable: true,
// // (
// //     /bot|crawler|spider|crawling/i.test(navigator.userAgent)
// //     || window.matchMedia("(prefers-reduced-motion: reduce)").matches
// //   ),
//   startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
//   initClassName: 'aos-init', // class applied after initialization
//   debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
//   throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
//   // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
//   offset: 50, // offset (in px) from the original trigger point
//   delay: 50, // values from 0 to 3000, with step 50ms
//   duration: 800, // values from 0 to 3000, with step 50ms
//   easing: 'ease-in-out', // default easing for AOS animations
//   once: false, // whether animation should happen only once - while scrolling down
//   mirror: false, // whether elements should animate out while scrolling past them
//   anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
// });
//use window.scrollY
var scrollpos = window.scrollY;
var header = document.getElementById("theme-page-navigation");
function add_class_on_scroll() {
    header.classList.add("bg-gray-900");
}
function remove_class_on_scroll() {
    header.classList.remove("bg-gray-900");
}
window.addEventListener('scroll', function () {
    //Here you forgot to update the value
    scrollpos = window.scrollY;
    if (scrollpos > 10) {
        add_class_on_scroll();
    }
    else {
        remove_class_on_scroll();
    }
});
