"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initMailDecrypt = void 0;
const constants_1 = require("../shared/constants");
function initMailDecrypt() {
    if (constants_1.debug)
        console.log('initMailDecrypt');
    /* mailencrypting */
    setTimeout(function () {
        let mailElement = document.querySelectorAll('[data-madr1]:not(.madr-done)');
        mailElement.forEach((mail, index) => {
            const maddr = mail.getAttribute('data-madr1') + '@' + mail.getAttribute('data-madr2') + '.' + mail.getAttribute('data-madr3');
            const linktext = mail.getAttribute('data-linktext') ? mail.getAttribute('data-linktext') : maddr;
            const a = document.createElement('a');
            a.setAttribute('href', `mailto:${maddr}`);
            a.innerHTML = linktext;
            if (mail.parentElement)
                mail.parentElement.appendChild(a);
            mail.classList.add('madr-done');
            mail.style.display = 'none';
        });
    }, 500);
}
exports.initMailDecrypt = initMailDecrypt;
